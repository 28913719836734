<template>
  <div class="login-wrap">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">
        addy
        <span v-if="isAddyPlusLogin">Business</span>
        <span v-else>Personal</span>
        Login
      </div>
      <ValidationObserver ref="loginForm" v-slot="{ valid }">
        <ValidationProvider ref="emailValidation" name="email" :mode="customValidation" rules="required|email" v-slot="{ passed, failed }">
          <div class="field email-field">
            <div class="control has-icons-right">
              <input
                data-testid="email"
                :class="`input is-medium-height ${classForField({ passed, failed })}`"
                type="email"
                placeholder="Email"
                v-model.trim="email"
                @keyup.enter="doLogin"
                autofocus
              >
              <span class="icon is-small is-right">
                <img
                  v-show="failed"
                  class="icon-exclamation"
                  src="@assets/icons/exclamation.svg"
                  alt="is-danger"
                  data-testid="email-failed"
                  data-cy="mismatch-confirm-password-exclamation">
                <img
                  v-show="passed"
                  class="icon-check"
                  src="@assets/icons/check.svg"
                  alt="is-success"
                  data-testid="email-passed"
                  data-cy="confirm-password-checkmark">
              </span>
            </div>
            <p class="help is-danger" data-testid="email-error-message" v-show="failed">The email field must be a valid email.</p>
          </div>
        </ValidationProvider>
        <ValidationProvider name="password" :mode="customValidation" rules="required" v-slot="{ errors, passed, failed }">
          <div class="field password-field">
            <div class="control has-icons-right">
              <input
                :class="`input is-medium-height ${classForField({ passed, failed })}`"
                :type="revealedPassword ? 'text' : 'password'" placeholder="Password"
                v-model.trim="password"
                @keyup.enter="doLogin"
              >
              <span class="icon is-small is-right" @click="revealedPassword = !revealedPassword">
                <img v-show="!revealedPassword" src="@assets/icons/eye.svg" alt="is-danger" class="icon-eye" data-cy="eye-on">
                <img v-show="revealedPassword" src="@assets/icons/eye-off.svg" alt="is-success" class="icon-eye" data-cy="eye-off">
              </span>
            </div>
            <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
          </div>
        </ValidationProvider>
        <PromiseLoadingButton
          :action="doLogin"
          :disabled="!valid || submittingLogin"
          :hasBoxShadow="false"
          class="button has-background-primary is-medium-height is-fullwidth is-rounded"
          data-testid="button"
        >Login</PromiseLoadingButton>
        <div :class="`has-text-centered has-text-primary forget-password ${resetPasswordDisabled ? 'disabled' : 'is-clickable'}`"
        @click="resetPassword">Forgot your password?</div>
      </ValidationObserver>
      <social-buttons mode="login"/>
    </div>
    <div class="has-text-centered to-signup" v-if="!isAddyPlusLogin">Don’t have an addy account?
      <span @click="toSignupPage" class="has-text-blue is-clickable">Sign up</span>
    </div>
    <ConfirmModal :title="confirmModalTitle" :text="confirmModalText" :showModal="showModal" @closeModal="showModal = false"></ConfirmModal>
  </div>
</template>
<script>
import PromiseLoadingButton from '@components/button/promise-loading-button.vue'
import SocialButtons from '@components/button/social-buttons.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import formMixins from '@utils/mixins/form-mixins.js'
import { initializePendo } from '@utils/common-methods/pendo'
import { embeddedInIos } from '@utils/common-methods/common'
import { auth, resetPassword } from '@api/auth'
import { getInvestorData } from '@api/signup'
import { getMembershipDetails } from '@api/common'
import { mapState } from 'vuex'
import { getSharedCookieDomain } from '@utils/common-methods/getSharedCookieDomain'
import { logOutAndCleanSession } from '@utils/common-methods/auth'
import axios from '@lib/axios/middleware'

export default {
  mixins: [formMixins],
  components: {
    PromiseLoadingButton,
    ConfirmModal,
    SocialButtons
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      revealedPassword: false,
      confirmModalTitle: '',
      confirmModalText: '',
      showModal: false,
      submittingLogin: false,
      disableResetPassword: true,
    }
  },
  mounted() {
    this.disableResetPassword = false
    if (this.$cookies.isKey('last_login_error')) {
      const lastLoginError = this.$cookies.get('last_login_error')
      this.confirmModalTitle = 'Login Failed'
      this.confirmModalText = lastLoginError.replace(/\+/g, ' ')
      this.showModal = true
      this.$cookies.remove('last_login_error', '/', getSharedCookieDomain())
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    resetPasswordDisabled() {
      if (!this.$refs.emailValidation) return this.disableResetPassword
      return this.$refs.emailValidation.flags.invalid || this.disableResetPassword
    },
    redirectUrl() {
      const route = this.$route.query.redirect_url
      return (route && route.startsWith('/')) ? route : '/dashboard'
    },
    isAddyPlusLogin() {
      return this.$route.path.startsWith('/addyPlus')
    },
  },
  methods: {
    customValidation() {
      if (!this.email && !this.password) {
        return {
          on: ['input']
        }
      }
      return { on: ['input', 'blur'] }
    },
    toSignupPage() {
      this.$router.push('/signup/create')
    },
    showAlertModal(errMsg) {
      this.confirmModalTitle = 'Login Failed'
      this.confirmModalText = errMsg
      this.showModal = true
    },
    doLogin() {
      if (this.submittingLogin) return
      this.submittingLogin = true
      this.$refs.loginForm.validate().then((success) => {
        if (!success) {
          this.submittingLogin = false
          return
        }

        const payload = {
          email: this.email,
          password: this.password,
        }

        auth(payload).then((data) => {
          if (data.success) {
            if (embeddedInIos()) return window.webkit.messageHandlers.didSignIn.postMessage(data.data)

            localStorage.setItem('access_token', data.data.accessToken)
            localStorage.setItem('refresh_token', data.data.refreshToken)

            this.isAddyPlusLogin ? this.handleAddyPlusLogin() : this.setupProfilePriorToDashboard()
          }
        }).catch((error) => {
          this.submittingLogin = false
          switch (error.response.data.error) {
          case 'mfa_required':
            if (localStorage.getItem('access_token')) {
              logOutAndCleanSession().then(() => {
                this.toFactorAuthenticationPage(error.response.data.mfa_token)
              })
            } else {
              this.toFactorAuthenticationPage(error.response.data.mfa_token)
            }
            break
          case 'account_frozen':
            this.showAlertModal(error.response.data.error_description)
            break
          default:
            this.showAlertModal('Invalid Email/Password Combination.')
          }
        })
      })
    },
    toFactorAuthenticationPage(mfaToken) {
      localStorage.setItem('mfa_token', mfaToken)
      this.$router.push('/addyPlus/factorAuthentication')
    },
    handleAddyPlusLogin() {
      this.$router.push('/addyPlus/dashboard')
    },
    setupProfilePriorToDashboard() {
      axios.all([
        getMembershipDetails(),
        getInvestorData(),
      ]).then((data) => {
        if (data[0].success) localStorage.setItem('membershipDetails', JSON.stringify(data[0].data))

        this.submittingLogin = false
        localStorage.setItem('investor', JSON.stringify(data[1]))
        this.mixpanel.identify(this.email)
        this.$store.commit('mixpanel/setIdentity', true)

        const {
          id,
          email,
        } = data[1]
        initializePendo(id, email)

        this.$router.push(this.redirectUrl)
      })
    },
    resetPassword() {
      if (this.resetPasswordDisabled) return
      if (!this.email) {
        this.confirmModalTitle = 'Reset Password Failed'
        this.confirmModalText = 'Please input an email!'
        this.showModal = true
        return
      }
      this.disableResetPassword = true
      const timeout = setTimeout(() => {this.disableResetPassword = false}, 60000)
      resetPassword({
        email: this.email,
      }).then((data) => {
        if (data.success) {
          this.confirmModalTitle = 'Password Reset'
          this.confirmModalText = 'Password Reset! Please check your email for instructions.'
        } else {
          this.confirmModalTitle = 'Reset Password Failed'
          this.confirmModalText = data.error
          this.disableResetPassword = false
          clearTimeout(timeout)
        }
        this.showModal = true
      })
    },
  }
}
</script>
<style lang="scss" scoped>

.login-wrap {
  padding: 0 25px;
  padding-bottom: 30px;
  > .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 25px;
    padding-bottom: 34px;
    margin-bottom: 29px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      margin-bottom: 45px;
    }
    .forget-password {
      margin-top: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .email-field {
      margin-bottom: 8px;
    }
    .password-field {
      margin-bottom: 46px;
    }
  }
  .to-signup {
    font-size: 16px;
    line-height: 19px;
    color: #939598;
    padding-bottom: 66px;
    > span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
