export const getSharedCookieDomain = () => {
  return window.location.hostname.replace(/^\w+\./, '')
  /* switch (window.location.hostname) {
  case 'canada.addyinvest-integration.com':
  case 'app.addyinvest-integration.com':
    return 'addyinvest-integration.com'
  case 'canada.addyinvest-staging.com':
  case 'app.addyinvest-staging.com':
    return 'addyinvest-staging.com'
  case 'canada.addyinvest.com':
  case 'app.addyinvest.com':
    return 'addyinvest.com'
  default:
    return 'localhost'
  }*/
}
